import instance from "@/utils/request"
const baseURL = '/user/';

/**
 * 获取当前用户信息
 * @param {Number} id 用户id
 * @returns 响应结果
 */
export const getUserMessageAPI = (id) => {
    return instance({
        url: baseURL + id,
    })
}

/**
 * 修改用户密码
 * @param {JSON} param 请求参数
 * @returns 响应结果
 */
export const changePasswordAPI = (param) => {
    return instance({
        url:baseURL + 'password',
        method:'put',
        data:param
    })
}