<template>
  <card-item
    class="card"
    title="箱体数据统计"
    cardWidth="100%"
    cardHeight="100%"
  >
    <template v-slot:title>
      <!-- 场区选择 -->
      <el-select
        v-model="fieldValue"
        value-key="id"
        placeholder="选择场区"
        style="width: 100%; height: 100%"
        @change="fieldChange"
        :teleported="false"
        class="field-select"
      >
        <el-option
          v-for="item in fieldList"
          :key="item.id"
          :label="item.name"
          :value="item"
        />
      </el-select>
    </template>
    <template v-slot:content>
      <!-- 数据显示 -->
      <el-table
        :data="tableData"
        style="
          width: 90%;
          height: 80%;
          font-size: 16rem;
          margin-top: 5%;
          margin-left: 5%;
        "
        :row-class-name="tableRowClassName"
        size="mini"
        @row-click="changeBarnInfo"
      >
        <el-table-column prop="barnName" label="箱体名称" />
        <el-table-column prop="category" label="种植种类" />
        <el-table-column prop="quantity" label="种植量" />
      </el-table>
    </template>
  </card-item>
</template>
  
<script setup>
import CardItem from "@/components/cards/card.vue";
import { useFieldStore, useBarnStore } from "@/stores/barn";
import { queryBarnDataAPI } from "@/apis/monitorAPI";
import { getBarnListAPI } from "@/apis/overviewAPI";
import { getFieldMessageAPI } from "@/apis/fieldAPI";
import { onMounted, ref, watch } from "vue";

//获取场区信息
const fieldStore = useFieldStore();
const barnStore = useBarnStore();

//建表工具
const tableRowClassName = ({ row, rowIndex }) => {
  if ((rowIndex + 1) % 2 == 0) {
    return "second-row";
  } else {
    return "first-row";
  }
};

//连接后台数据,构建场区列表
const fieldChange = (e) => {
  fieldStore.changeFieldId(e.id);
};
const fieldValue = ref({
  id: "",
  name: "",
});
const fieldList = ref([]);
const getFieldList = async () => {
  const promises = fieldStore.fieldIdList.map(async (item) => {
    const { data } = await getFieldMessageAPI(item);
    const id = data.id;
    const name = data.name;
    return {
      id,
      name,
    };
  });
  fieldList.value = await Promise.all(promises);

  fieldValue.value.id = fieldStore.fieldId;
  fieldValue.value.name = fieldList.value[0].name;
};
const barnList = ref([]);

const tableData = ref([]);

const queryBarnData = async () => {
  const { data } = await queryBarnDataAPI(fieldStore.fieldId);
  tableData.value = data.barnList;
};

//点击函数
const changeBarnInfo = (row, event, column) => {
  barnStore.changeBarnId(row.id);
  barnStore.changeBarnName(row.barnName);
};

//数据初始化
queryBarnData();
getFieldList();

onMounted(() => {
  watch(
    () => fieldStore.fieldId,
    async () => {
      const res = await getBarnListAPI(fieldStore.fieldId);
      barnList.value = res.data;
      barnStore.changeBarnName(barnList.value[0].barnName);
      barnStore.changeBarnId(barnList.value[0].id);
      queryBarnData();
    }
  );
});
</script>
  
<style scoped lang="less">
.card {
  ::v-deep .el-table {
    .first-row {
      background: @darkerGreen;
    }
    .second-row {
      background: @transparent;
    }
    --el-table-tr-bg-color: @transparent;
    --el-table-bg-color: @transparent;
    --el-table-header-bg-color: @transparent;
    --el-table-border-color: @transparent;
    --el-table-header-text-color: @green;
    --el-table-text-color: white;
    --el-table-row-hover-bg-color: @transparent;
  }
  ::v-deep .el-table__header-wrapper {
    font-size: 22rem;
  }
}
</style>

<style scoped lang="less">
.field-select{
  --el-fill-color-blank: @darkerGreen;
  --el-border: 1px solid white;
  --el-text-color-regular: white;
}

:deep(.el-select__wrapper) {
  min-height: 100%;
  min-width: 100%;
  font-size: 22rem;
}

:deep(.el-popper.is-light .el-popper__arrow::before) {
  border: none;
  background: @darkerGreen !important;
}
:deep(.el-popper.is-light) {
  background: @darkerGreen;
}
:deep(.el-select-dropdown__item) {
  text-align: left;
  background: @darkerGreen;
  border: none;
  color: #fff;
}
</style>
