<template>
  <!-- 后台头部组件 -->
  <el-menu
    :default-active="activeIndex"
    class="header-menu"
    mode="horizontal"
    :ellipsis="false"
    @select="handleSelect"
  >
    <!-- logo部分 -->
    <el-menu-item index="0">
      <img style="width: 100px" src="@/assets/logo.png" alt="logo" />
    </el-menu-item>
    <!-- 场区选择 -->
    <el-menu-item index="1" class="field">
      <el-select
        v-model="fieldValue"
        value-key="id"
        placeholder="选择场区"
        style="width: 180px"
        @change="fieldChange"
      >
        <el-option
          v-for="item in fieldList"
          :key="item.id"
          :label="item.name"
          :value="item"
        />
      </el-select>
    </el-menu-item>
    <!-- 畜舍选择 -->
    <el-menu-item index="2" class="choose">
      <el-select
        v-model="barnValue"
        value-key="id"
        placeholder="选择箱体"
        style="width: 180px"
        @change="barnChange"
      >
        <el-option
          v-for="item in barnList"
          :key="item.id"
          :label="item.barnName"
          :value="item"
        />
      </el-select>
    </el-menu-item>
    <!-- 退出登录 -->
    <div class="flex-grow" />
    <el-menu-item index="3">
      <router-link :to="{ path: '/main-screen' }"> 返回大屏 </router-link>
    </el-menu-item>
    <el-menu-item index="4" @click="logout">退出登录</el-menu-item>
  </el-menu>
</template>

<script setup>
import { useBarnStore, useFieldStore } from "@/stores/barn";
import { getBarnListAPI } from "@/apis/overviewAPI";
import { getFieldMessageAPI } from "@/apis/fieldAPI";
import { onMounted, ref, watch } from "vue";
import { useUserStore } from "@/stores/user";
import { useRouter } from "vue-router";

//从仓库获取当前场区id
const fieldStore = useFieldStore();

//定义切换列表选项以更新仓库中畜舍id值
const barnStore = useBarnStore();
const barnChange = (e) => {
  barnStore.changeBarnId(e.id);
  barnStore.changeBarnName(e.barnName);
};
const fieldChange = (e) => {
  fieldStore.changeFieldId(e.id);
}

//连接后台数据,构建场区、畜舍列表
const fieldValue = ref({
  id: "",
  name: "",
});
const fieldList = ref([]);
const getFieldList = async () => {
  const promises = fieldStore.fieldIdList.map(async item => {
    const {data} = await getFieldMessageAPI(item)
    const id = data.id
    const name = data.name
    return{
      id,
      name
    }
  })
  fieldList.value = await Promise.all(promises);

  fieldValue.value.id = fieldStore.fieldId;
  fieldValue.value.name = fieldList.value[0].name;
};

const barnValue = ref({
  id: "",
  barnName: "",
});
const barnList = ref([]);
const getBarnList = async (fieldId) => {
  const res = await getBarnListAPI(fieldId);
  barnList.value = res.data;
  barnValue.value.id = barnStore.barnId;
  barnValue.value.barnName = barnStore.barnName;
};

//获取数据
getFieldList();
getBarnList(fieldStore.fieldId);

//退出登录
const userStore = useUserStore();
const router = useRouter();
const logout = () => {
  userStore.clearToken();
  router.push({ path: "/" });
};

//页面刷新函数
onMounted(() => {
  watch(
    () => barnStore.barnId,
    () => {
      barnValue.value = {
        id: barnStore.barnId,
        barnName: barnStore.barnName,
      };
    }
  );
  watch(
    () => fieldStore.fieldId,
    async () => {
      const res = await getBarnListAPI(fieldStore.fieldId);
      barnList.value = res.data;
      barnStore.changeBarnName(barnList.value[0].barnName)
      barnStore.changeBarnId(barnList.value[0].id)
    }
  );
});
</script>

<style scoped lang="less">
.header-menu {
  width: 100%;
  height: 60px;
  left: 50%;
  transform: translateX(-50%);
}
.flex-grow {
  flex-grow: 1;
}
</style>