<template>
  <!-- 全局层面 -->
  <div class="page">
    <!-- 顶部框架 -->
    <el-row class="box-top">
      <!-- 标题栏 -->
      <el-col :span="4" :offset="4">
        <div id="date">{{ currentDate }}</div>
        <div id="time">{{ currentTime }}</div>
      </el-col>
      <el-col :span="8">
        <h1 id="title">光储氢智慧种植系统</h1>
        <div id="sub-title">INTELLIGENT PLANTING SYSTEM</div>
      </el-col>
      <el-col :span="4">
        <div class="barn-name">{{ barnStore.barnName }}</div>
      </el-col>
    </el-row>
    <!-- 主体框架 -->
    <el-row class="box-main" :gutter="10">
      <!-- 左区 -->
      <el-col :span="5" style="height: 100%">
        <el-row class="left-top-card">
          <el-col :span="24">
            <environment-card></environment-card>
          </el-col>
        </el-row>
        <el-row class="left-bottom-card">
          <el-col :span="24">
            <box-data-card></box-data-card>
          </el-col>
        </el-row>
      </el-col>
      <!-- 中区 -->
      <el-col :span="14" style="height: 100%">
        <el-row class="middle-top-card">
          <el-col :span="24">
            <sensor-card></sensor-card>
          </el-col>
        </el-row>
        <el-row class="middle-bottom-card" type="flex" justify="center">
          <el-col :span="20">
            <router-link :to="{ path: '/backstage' }">
              <img class="box" v-bind:src="boxUrl" alt="box" />
            </router-link>
          </el-col>
        </el-row>
      </el-col>
      <!-- 右区 -->
      <el-col :span="5" style="height: 100%">
        <el-row class="right-top-card">
          <el-col :span="24">
            <equipment-card></equipment-card>
          </el-col>
        </el-row>
        <el-row class="right-bottom-card">
          <el-col :span="24">
            <warning-data-card></warning-data-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- 底部框架 -->
    <el-row class="box-bottom"></el-row>
  </div>
</template>
    
<script setup>
import environmentCard from "./cards/environment-card.vue";
import boxDataCard from "./cards/box-data-card.vue";
import sensorCard from "./cards/sensor-card.vue";
import equipmentCard from "./cards/equipment-card.vue";
import warningDataCard from "./cards/warning-data-card.vue";
import { useBarnStore, useFieldStore } from "@/stores/barn";
import { getBarnListAPI } from "@/apis/overviewAPI";
import { onMounted, ref, watchEffect, watch } from "vue";

//调取图片路径函数
const getImgUrl = (add) => {
  return require("@/assets/" + add);
};
const boxUrl = getImgUrl("container.png");

const currentTime = ref("");
const currentDate = ref("");
const showTime = () => {
  /* 显示日期函数 */
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const hour = now.getHours();
  const min = now.getMinutes().toString().padStart(2, "0");
  const second = now.getSeconds().toString().padStart(2, "0");
  const date = year + "年" + month + "月" + day + "日";
  const time = hour + ":" + min + ":" + second;
  return [date, time];
};

//从仓库获取当前场区id
const fieldStore = useFieldStore();

//定义切换列表选项以更新仓库中箱体id值
const barnStore = useBarnStore();

//连接后台数据,构建箱体列表
const barnValue = ref({
  id: "",
  barnName: "",
});
const barnList = ref([]);
const getBarnList = async () => {
  const res = await getBarnListAPI(fieldStore.fieldId);
  barnList.value = res.data;
  barnValue.value = res.data[0];
};
//获取数据
getBarnList();

//退出登录
// const userStore = useUserStore();
// const router = useRouter();
// const logout = () => {
//   userStore.clearToken();
//   router.push({ path: "/" });
// };

watchEffect(() => {
  /* 实时时间更新 */
  setInterval(() => {
    const timeData = showTime(new Date());
    currentDate.value = timeData[0];
    currentTime.value = timeData[1];
  }, 1000);
});

//页面刷新函数
onMounted(() => {
  watch(
    () => barnStore.barnId,
    () => {
      barnValue.value = {
        id: barnStore.barnId,
        barnName: barnStore.barnName,
      };
    }
  );
});
</script>
    
<style scoped lang="less">
.page {
  /* 网页背景 */
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  background-image: url(@/assets/background-pic.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.box-top {
  /* 顶部布局框 */
  height: 230rem;
  width: 100vw;
  background-image: url(@/assets/top-frame.png);
  background-size: 100%;
  background-repeat: no-repeat;
  .barn-name{
    width: 100%;
    height: 100%;
    margin-top: 60rem;
    font-size: 40rem;
    color: white;
    text-align: center;
    font-family: @text;
    font-weight: bold;
  }
}

.box-main {
  /* 主体布局框 */
  height: 75vh;
  width: 96vw;
  left: 2vw;
  .left-top-card {
    height: 55%;
    width: 100%;
  }
  .left-bottom-card {
    height: 45%;
    width: 100%;
  }
  .middle-top-card {
    height: 15%;
    width: 100%;
  }
  .middle-bottom-card {
    height: 85%;
    width: 100%;
    .box {
      width: 90%;
    }
  }
  .right-top-card {
    .left-top-card;
  }
  .right-bottom-card {
    .left-bottom-card;
  }
}

.box-bottom {
  /* 底部布局框 */
  margin-top: -90rem;
  height: 200rem;
  width: 100vw;
  background-image: url(@/assets/bottom-frame.png);
  background-size: 100%;
  background-repeat: no-repeat;
}

#title {
  /* 主标题 */
  font-size: 45rem;
  color: white;
  position: absolute;
  font-family: @mainTitle;
  left: 50%;
  transform: translate(-50%, 0%);
}
#sub-title {
  /* 副标题 */
  font-size: 20rem;
  color: white;
  position: absolute;
  font-family:'Segoe UI';
  color: @light;
  left: 50%;
  transform: translate(-50%, 100rem);
}
#date {
  /* 时间显示 */
  margin-top: 60rem;
  font-size: 20rem;
  color: white;
  text-align: center;
  font-family: @text;
  font-weight: bold;
}
#time {
  /* 时间显示 */
  position: relative;
  font-size: 30rem;
  color: white;
  text-align: center;
  font-weight: bold;
}
</style>