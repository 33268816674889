<template>
  <card-item class="card" title="设备情况" cardWidth="100%" cardHeight="100%">
    <template v-slot:content>
      <!-- 情况总览 -->
      <el-row class="outline" type="flex" align="middle">
        <el-col :span="5" :offset="4" class="content">
          <div class="key-data">
            <div class="emphasize">{{ total }}</div>
          </div>
          <div style="text-align: center">设备总数</div>
          <div class="ico">
            <img v-bind:src="lightUrl" alt="light" class="image" />
          </div>
        </el-col>
        <el-col :span="5" :offset="4" class="content">
          <div class="key-data">
            <div class="emphasize">{{ open }}</div>
          </div>
          <div style="text-align: center">运行数量</div>
          <div class="ico">
            <img v-bind:src="lightUrl" alt="light" class="image" />
          </div>
        </el-col>
      </el-row>
      <!-- 设备总览 -->
      <el-scrollbar height="45%">
        <p v-for="item in tableData" :key="item" class="data-item">
          <el-row
            class="table"
            style="width: 90%; height: 100%; padding-left: 5%"
            type="flex"
            align="middle"
          >
            <el-col :span="2">
              <img
                v-bind:src="dotUrl"
                alt="dot"
                style="width: 100%; height: 100%"
              />
            </el-col>
            <el-col :span="7">
              {{ item.name }}
            </el-col>
            <el-col :span="5" :offset="4" class="switch">
              <el-switch
                v-model="item.switchValue"
                disabled
                inline-prompt
                size="middle"
                active-value="开"
                inactive-value="关"
                width="100rem"
                style="
                  --el-switch-on-color: #8bffbb;
                  --el-switch-off-color: #6a6e6e;
                "
              />
            </el-col>
            <el-col :span="2" :offset="2" class="operation">
              {{ item.switchValue }}
            </el-col>
          </el-row>
        </p>
      </el-scrollbar>
    </template>
  </card-item>
</template>
  
  <script setup>
import CardItem from "@/components/cards/card.vue";
import { useBarnStore } from "@/stores/barn";
import { queryEquipmentDataAPI } from "@/apis/monitorAPI";
import { onMounted, ref, watch } from "vue";

//调取图片路径函数
const getImgUrl = (add) => {
  return require("@/assets/" + add);
};
const dotUrl = getImgUrl("dot.png");
const lightUrl = getImgUrl("light.png");

const barnStore = useBarnStore();

//数据构建
const total = ref(0);
const open = ref(0);

//表格数据
const tableData = ref([]);

const queryEquipmentData = async () => {
  total.value = 0;
  open.value = 0;
  const { data } = await queryEquipmentDataAPI(barnStore.barnId);
  tableData.value = data.map((item) => {
    if (item.operation != null && item.operation.includes("开")) {
      open.value += 1;
    }
    total.value = data.length;
    return {
      ...item,
      switchValue: item.operation
        ? item.operation.includes("开")
          ? "开"
          : "关"
        : "关",
    };
  });
};

//初始化
queryEquipmentData();

onMounted(() => {
  watch(
    () => barnStore.barnId,
    () => {
      queryEquipmentData();
    }
  );
});
</script>
  
<style scoped lang="less">
.card {
  .outline {
    height: 40%;
    width: 100%;
    .content {
      text-align: center;
      color: @green;
      font-size: 25rem;
      .key-data {
        background-image: url(@/assets/base.png);
        height: 50%;
        width: 100rem;
        background-size: 100%;
        background-repeat: no-repeat;
      }
      .emphasize {
        font-size: 50rem;
        margin-top: 5rem;
        padding-top: 30rem;
        font-weight: bold;
      }
    }
  }
  .image {
    display: inline-block;
    width: 100%;
  }
  .data-item {
    height: 20%;
    width: 100%;
    font-size: 20rem;
    .switch {
      width: 100%;
    }
    .operation {
      color: @green;
      font-weight: bold;
    }
  }
}
</style>