<template>
  <div class="card">
    <div class="title">环控规则</div>
    <!-- 温度控制 -->
    <el-row class="tool-row" align="middle" style="margin-top: 10px">
      <el-col :span="5" style="font-weight:bold"> 温度控制规则（℃） </el-col>
    </el-row>
    <el-row class="tool-row" align="middle" style="margin-top: 10px">
      <el-col :span="3"> 温度阈值 </el-col>
      <el-col :span="2">
        <el-input-number
          v-model="temHigh"
          size="small"
          :controls="false"
          :min=5
        ></el-input-number>
      </el-col>
      <el-col :span="3" :offset="2"> 温控偏差 </el-col>
      <el-col :span="2">
        <el-input-number
          v-model="temWave"
          size="small"
          :controls="false"
        ></el-input-number>
      </el-col>
    </el-row>
    <!-- 湿度控制 -->
    <el-row class="tool-row" align="middle" style="margin-top: 10px">
      <el-col :span="5" style="font-weight:bold"> 湿度控制规则（%） </el-col>
    </el-row>
    <el-row class="tool-row" align="middle" style="margin-top: 10px">
      <el-col :span="3"> 湿度阈值 </el-col>
      <el-col :span="2">
        <el-input-number
          v-model="rhHigh"
          size="small"
          :controls="false"
          :min=30
        ></el-input-number>
      </el-col>
      <el-col :span="3" :offset="2"> 湿控偏差 </el-col>
      <el-col :span="2">
        <el-input-number
          v-model="rhWave"
          size="small"
          :controls="false"
        ></el-input-number>
      </el-col>
    </el-row>
    <!-- 气体控制 -->
    <el-row class="tool-row" align="middle" style="margin-top: 10px">
      <el-col :span="5" style="font-weight:bold"> 气体控制规则（ppm） </el-col>
    </el-row>
    <el-row class="tool-row" align="middle" style="margin-top: 10px">
      <el-col :span="3"> 浓度阈值 </el-col>
      <el-col :span="2">
        <el-input-number
          v-model="gasHigh"
          size="small"
          :controls="false"
          min=0
        ></el-input-number>
      </el-col>
      <el-col :span="3" :offset="2"> 气体偏差 </el-col>
      <el-col :span="2">
        <el-input-number
          v-model="gasWave"
          size="small"
          :controls="false"
        ></el-input-number>
      </el-col>
    </el-row>
    <!-- 修改按钮 -->
    <el-row class="tool-row" align="middle">
      <el-col :span="2">
        <el-button @click="update" type="primary" disabled>修改</el-button>
      </el-col>
    </el-row>
  </div>
</template>
    
<script setup>
import { useBarnStore } from "@/stores/barn";
import { queryControlRuleAPI, updateControlRuleAPI } from "@/apis/controlManageAPI";
import { onMounted, ref, watch } from "vue";

//获取畜舍id
const barnStore = useBarnStore();

//接收数据
const ruleId = ref("");
const temHigh = ref("");
const temWave = ref("");
const rhHigh = ref("");
const rhWave = ref("");
const gasHigh = ref("");
const gasWave = ref("");

const deviceName = ref("");

//参数相关
const queryControlRule = async () => {
  const { data } = await queryControlRuleAPI(barnStore.barnId);
  ruleId.value = data.id;
  deviceName.value = data.deviceName;
  temHigh.value = data.temHigh;
  temWave.value = data.temWave;
  rhHigh.value = data.rhHigh;
  rhWave.value = data.rhWave;
  gasHigh.value = data.gasHigh;
  gasWave.value = data.gasWave;
};
const updateControlRule = async () => {
  const {data} = await updateControlRuleAPI({
    id: ruleId.value,
    deviceName: deviceName.value,
    temHigh:temHigh.value,
    temWave:temWave.value,
    rhHigh:rhHigh.value,
    rhWave:rhWave.value,
    gasHigh:gasHigh.value,
    gasWave:gasWave.value
  })
}

//按钮赋值
const update = () => {
  updateControlRule();
};
//数据初始化
queryControlRule();

//页面刷新函数
onMounted(() => {
  watch(
    () => barnStore.barnId,
    () => {
      queryControlRule();
    }
  );
});
</script>
    
<style scoped lang="less">
.card {
  width: 100%;
  min-height: 550px;
  background-color: white;
  .title {
    font-family: @title;
    font-weight: bold;
    font-size: 22px;
    height: 30px;
    line-height: 30px;
    padding-top: 20px;
    padding-left: 35px;
  }
  .tool-row {
    width: 850px;
    height: 40px;
    left: 35px;
    margin-top: 15px;
    font-size: 16px;
    :deep(.el-input-number--small) {
      width: 80px;
    }
  }
}
</style>