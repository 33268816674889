<template>
  <div class="card">
    <div class="title">箱体信息</div>
    <el-row class="tool-row" align="middle" style="margin-top: 0">
      <el-col :span="2">
        <el-button @click="add" disabled>+新添</el-button>
      </el-col>
    </el-row>
    <el-table class="table" :data="dataList" border="true">
      <el-table-column type="index" label="序号" align="center" width="60" />
      <el-table-column prop="barnName" label="箱体名称" />
      <el-table-column prop="category" label="箱体类别" width="120" />
      <el-table-column prop="quantity" label="种植量" width="120" />
      <el-table-column prop="maxQuantity" label="最大种植量" width="120" />
      <el-table-column fixed="right" label="操作" width="120">
        <template #default="scope">
          <el-button
            link
            type="primary"
            size="small"
            @click.prevent="modifyRow(scope.$index)"
          >
            修改
          </el-button>
          <el-button
            link
            type="primary"
            size="small"
            @click.prevent="deleteRow(scope.$index)"
            disabled
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="tool-row" align="middle" style="height: 50px; margin-top: 0">
      <el-col :span="2">
        <el-button @click="output" type="primary">导出</el-button>
      </el-col>
    </el-row>
  </div>
  <data-dialog
    :modifyRule="modifyRule"
    :title="dialogTitle"
    :isSave="isSave"
    :rowData="rowData"
    @close-dialog="closeDialog"
    @submit-data="updateData"
    @save-data="saveData"
    :before-close="closeDialog"
  ></data-dialog>
</template>
    
<script setup>
import { ElMessageBox } from "element-plus";
import { useFieldStore } from "@/stores/barn";
import DataDialog from "./dialog/dialog.vue";
import {
  queryBarnAPI,
  saveBarnAPI,
  removeBarnAPI,
  updateBarnAPI,
} from "@/apis/barnAPI";
import { onMounted, ref, toRaw, watch } from "vue";

//获取畜舍id
const fieldStore = useFieldStore();

//参数相关
const barnName = ref("");

//接收数据
const dataList = ref([]);

//API函数
const queryBarn = async () => {
  const { data } = await queryBarnAPI({
    fieldId: fieldStore.fieldId,
    pageSize: 0,
  });
  dataList.value = data.list;
};
const updateBarn = async (data) => {
  await updateBarnAPI({
    id: data.id,
    barnName: data.barnName,
    category: data.category,
    quantity: data.quantity,
    maxQuantity: data.maxQuantity,
    fieldId: fieldStore.fieldId,
  });
};
const removeBarn = async (id) => {
  await removeBarnAPI(id);
};
const saveBarn = async (data) => {
  await saveBarnAPI({
    id: data.id,
    barnName: data.barnName,
    category: data.category,
    quantity: data.quantity,
    maxQuantity: data.maxQuantity,
    fieldId: fieldStore.fieldId,
  });
};

//数据初始化
queryBarn();

//选择按钮函数
const select = () => {
  queryBarn();
};
const output = async () => {
  await queryBarn();
  Download(dataList.value, fieldStore.fieldName + "畜舍列表");
};

//修改对话框相关
const modifyRule = ref(false);
const dialogTitle = ref("");
const rowData = ref({
  id: "",
  barnName: "",
  category: "",
  quantity: "",
  maxQuantity: "",
  fieldId: "",
});
const isSave = ref(false);

//修改按钮函数
const modifyRow = (i) => {
  rowData.value = dataList.value[i];
  dialogTitle.value = "修改信息";
  modifyRule.value = true;
  isSave.value = false;
};
const deleteRow = (i) => {
  ElMessageBox.confirm("是否删除数据？", "警告", {
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    type: "warning",
  })
    .then(async () => {
      await removeBarn(dataList.value[i].id);
      queryBarn();
    })
    .catch(() => {});
};
const add = () => {
  rowData.value = {
    id: "",
    barnName: "",
    category: "",
    quantity: "",
    maxQuantity: "",
    fieldId: "",
  };
  dialogTitle.value = "新建信息";
  isSave.value = true;
  modifyRule.value = true;
};
//对话框提交数据函数
const updateData = async (data) => {
  await updateBarn(toRaw(data));
  queryBarn();
  modifyRule.value = false;
};
const saveData = async (data) => {
  await saveBarn(toRaw(data));
  queryBarn();
  modifyRule.value = false;
};
//关闭对话框函数
const closeDialog = () => {
  modifyRule.value = false;
};
</script>
    
<style scoped lang="less">
.card {
  width: 100%;
  min-height: 550px;
  background-color: white;
  .title {
    font-family: @title;
    font-weight: bold;
    font-size: 22px;
    height: 30px;
    line-height: 30px;
    padding-top: 20px;
    padding-left: 35px;
  }
  .tool-row {
    width: 850px;
    height: 60px;
    left: 35px;
    margin-top: 15px;
  }
  .table {
    width: 850px;
    height: 440px;
    left: 50%;
    transform: translateX(-50%);
    font-family: @text;
    color: black;
  }
}
</style>