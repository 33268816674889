import { onMounted, onUnmounted } from "vue";

export function useDynamicRem(){
    // 设置基准值
  const BASE_WIDTH = 2560; // 设计稿的基准宽度
  const BASE_FONT_SIZE = 20; // 基准字体大小（px）

  // 动态计算并设置根元素的 font-size
  function setRootFontSize() {
    const currentWidth = window.innerWidth; // 当前窗口宽度
    let fontSize = (currentWidth / BASE_WIDTH) * BASE_FONT_SIZE;

    // 设置最小值和最大值
    const MIN_FONT_SIZE = 6; // 最小字体大小
    const MAX_FONT_SIZE = 24; // 最大字体大小
    fontSize = Math.max(MIN_FONT_SIZE, Math.min(fontSize, MAX_FONT_SIZE)) / 20;

    document.documentElement.style.fontSize = `${fontSize}px`;
  }

  // 防抖函数
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  // 初始化设置
  setRootFontSize();

  // 监听窗口大小变化
  const handleResize = debounce(setRootFontSize, 100);
  window.addEventListener('resize', handleResize);

  // 组件卸载时移除监听器
  onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
  });
}