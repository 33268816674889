<template>
  <!-- 全局层面，用于设置网页背景 -->
  <div class="page">
    <!-- 中心组件部分 -->
    <div class="middle-box">
      <img id="logo" v-bind:src="logoUrl" alt="良牧logo" />
      <!-- 登录主组件 -->
      <div class="main">
        <div class="title">
          <h1>新良牧智能环境监测平台</h1>
        </div>
        <div class="subtitle">
          <el-divider>用户名密码登录</el-divider>
        </div>
        <!-- 用户名与密码输入表单 -->
        <el-form
          class="login-form"
          ref="formRef"
          :model="loginData"
          :rules="rules"
          size="default"
          status-icon
        >
          <el-form-item prop="userName">
            <el-input
              v-model="loginData.userName"
              placeholder="输入用户名或手机号"
              maxlength="15"
              clearable
              prefix-icon="User"
            />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="loginData.password"
              type="password"
              placeholder="输入密码"
              maxlength="11"
              clearable
              show-password
              prefix-icon="Lock"
            />
          </el-form-item>
        </el-form>
        <!-- 附加组件：记住密码与忘记密码 -->
        <el-row class="row-tool">
          <!-- <el-col :span="2" :offset="6">
            <el-checkbox v-model="remember" label="记住密码" size="large" />
          </el-col> -->
          <!-- <el-col :span="2" :offset="6">
            <a class="forget" v-bind:href="forgetUrl" target="_blank"
              >忘记密码</a
            >
          </el-col> -->
        </el-row>
        <el-row class="row-button">
          <el-col :span="12" :offset="6">
            <el-button class="button" round @click="submit"> 登 录</el-button>
          </el-col>
        </el-row>
        <!-- <el-row class="row-note">
          <el-col :span="4" :offset="6">
            <a v-bind:href="noteUrl" class="note" target="_blank"
              >用户注册与企业注册须知</a
            >
          </el-col>
        </el-row> -->
      </div>
    </div>
    <!-- 首页说明 -->
    <div class="information">
      <p>当前版本信息: v1.0.1</p>
    </div>
  </div>
</template>

<script setup>
import { ref, toRaw } from "vue";
import { useRouter } from "vue-router";
import {useUserStore} from "@/stores/user"
import { useBarnStore, useFieldStore } from "@/stores/barn";
import { getUserMessageAPI } from "@/apis/userAPI";
import { getBarnListAPI } from "@/apis/overviewAPI";

const userStore = useUserStore()
const barnStore = useBarnStore()
const fieldStore = useFieldStore()

//调取图片路径函数
const getImgUrl = (add) => {
  return require("@/assets/" + add)
}
const logoUrl = getImgUrl("logo.png");

const forgetUrl = " "; //忘记密码链接
const noteUrl = " "; //用户须知链接

//表单输入项设置，制定表单输入与输出规则
const loginData = ref({
    userName: "",
    password: ""
})
const formRef = ref(null)
const rules = {
  userName: { required: true, message: "请输入账号", trigger: "blur" },
  password: { required: true, message: "请输入密码", trigger: "blur" },
};

// const remember = ref(true);
const router = useRouter();

const submit = () => {
  /* 登录验证函数 */
  formRef.value.validate(async (valid) => {
    if (!valid) {
      console.log("登录信息有误")
      return false;
    }
    await userStore.getToken(toRaw(loginData.value))

    // 获取用户场区信息
    const { data } = await getUserMessageAPI(userStore.userId)
    const fieldList = data.level.split(',').map(Number);
    fieldStore.changeFieldIdList(fieldList)
    fieldStore.changeFieldId(fieldList[0])

    const barnData = await getBarnListAPI(fieldStore.fieldId)
    barnStore.changeBarnId(barnData.data[0].id)
    barnStore.changeBarnName(barnData.data[0].barnName)

    router.push({ path: "/main-screen" });
  });
};

</script>

<style scoped lang="less">
.page {
  /* 网页背景 */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-image: url(@/assets/background.jpg);
  background-size: cover;
}

.middle-box {
  /* 中心主框架定位 */
  position: absolute;
  width: 600px;
  height: 510px;
  top: 50%;
  left: 50%;
  transform: translate(
    -50%,
    -55%
  ); /* 相对自身平移，使得中心点位于屏幕设定位置 */
}
.main {
  /* 登录组件框 */
  width: 500px;
  height: 300px;
  background-color: #fffffff1;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(
    -50%,
    -55%
  ); /* 相对自身平移，使得中心点位于屏幕设定位置 */
  .title {
    position: relative;
    top: 10px;
    text-align: center;
    color: @darkerGreen;
    text-shadow: 2px 2px 3px gray;
    font-size: 15px;
    font-family: @title;
    font-weight: bold;
  }
  .subtitle {
    position: relative;
    width: 90%;
    top: 15px;
    margin: auto;
    :deep(.el-divider__text) {
      color: black;
      font-size: 15px;
      font-weight: bold;
      background-color: @transparent;
    }
  }
}

.login-form {
  /* 登录表单 */
  position: relative;
  top: 20px;
  .el-form-item {
    /* 登录表单的输入框 */
    margin-bottom: 20px;
    position: relative;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

.row-tool {
  /* 登录组件布局 */
  position: relative;
  height: 40px;
  top: 10px;
}
.row-button {
  /* 登录按钮布局 */
  .row-tool;
}
.row-note {
  .row-tool;
}

.button {
  /* 登录按钮 */
  position: absolute;
  width: 300px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 15px;
}
.forget {
  /* 忘记密码链接 */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
}
.note {
  /* 用户须知链接 */
  position: absolute;
  font-size: 15px;
}

.information {
  /* 说明部分文字 */
  position: fixed;
  width: 100%;
  bottom: 10px;
  text-align: center;
  color: #ffffff;
  font-size: 15px;
  line-height: 5px;
}

#logo {
  /* 公司logo */
  width: 150px;
  height: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>