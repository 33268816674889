<template>
  <!-- 环境数据 -->
  <div class="large-card">
    <el-row class="table" style="width: 100%">
      <el-col :span="1">
        <LocationFilled
          style="width: 30px; height: 80px; margin-left: 20px"
        ></LocationFilled>
      </el-col>
      <el-col :span="3">
        <div class="barn-name">{{ props.barnName }}</div>
      </el-col>
      <el-col :span="12">
        <el-row>
          <el-col
            class="part-data"
            :span="7"
            v-for="(item, index) in environmentData"
            :key="index"
          >
            <div>{{ item.name }}</div>
            <div>
              <router-link @click="changeBarn" :to="{ path: '/environment' }">
                <span class="emphasize" @click="toEnvironment">{{
                  item.data
                }}</span
                ><span>{{ item.unit }}</span>
              </router-link>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col class="part-data" :span="2">
        <div>传感器数</div>
        <div>
          <router-link @click="changeBarn" :to="{ path: '/sensor' }">
            <span class="emphasize">{{ sensorCount }}</span>
          </router-link>
        </div>
      </el-col>
      <el-col class="part-data" :span="2">
        <div>报警数</div>
        <div>
          <router-link @click="changeBarn" :to="{ path: '/warning' }">
            <span class="emphasize">{{ warningCount }}</span>
          </router-link>
        </div>
      </el-col>
      <el-col class="part-data" :span="2" :offset="2">
        <div class="url" align="right">
          <router-link @click="changeBarn" :to="{ path: '/control' }">
            环控设置>>
          </router-link>
        </div>
        <div class="url" align="right">
          <router-link :to="{ path: '/warning-manage' }">
            报警管理>>
          </router-link>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import * as category from "@/constant/data-category";
import { useBarnStore } from "@/stores/barn";
import {
  getEnvironmentDataAPI,
  countWarningDataAPI,
} from "@/apis/overviewAPI";
import { querySensorCountAPI } from "@/apis/monitorAPI";
import { onMounted, ref, watch } from "vue";

//接收畜舍id
const barnStore = useBarnStore();

//接收父组件数据
const props = defineProps({
  barnId: Number,
  barnName: String,
});
//接收数据
const environmentData = ref([]);
const warningCount = ref("");
const sensorCount = ref("");

//获取数据
const getEnvironmentData = async () => {
  const { data } = await getEnvironmentDataAPI(props.barnId);
  environmentData.value = data;
};
const countSensor = async () => {
  const { data } = await querySensorCountAPI(props.barnId);
  sensorCount.value = data[0].number;
};
const countWarning = async () => {
  const { data } = await countWarningDataAPI(props.barnId);
  warningCount.value = data;
};

//替换箱体id
const changeBarn = () => {
  barnStore.changeBarnId(props.barnId);
  barnStore.changeBarnName(props.barnName);
};

//刷新后取一次数据
getEnvironmentData();
countSensor();
countWarning();

//页面刷新函数
onMounted(() => {
  watch(
    () => props.barnId,
    () => {
      getEnvironmentData();
      countSensor();
      countWarning();
    }
  );
});
</script>

<style scoped>
.table {
  height: 60px;
}
.text-row {
  position: relative;
  height: 80px;
  width: 100%;
  margin-left: 50px;
}
.barn-name {
  height: 80px;
  line-height: 80px;
  font-family: SimHei;
  font-weight: bold;
  font-size: 20px;
  margin-left: 15px;
}
.part-data {
  height: 15px;
  line-height: 15px;
  font-family: SimHei;
  font-weight: bold;
  font-size: 12px;
  margin-top: 15px;
  margin-left: 10px;
}
.emphasize {
  font-size: 30px;
  font-weight: bold;
  line-height: 30px;
}
</style>