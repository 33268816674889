<template>
  <!-- 侧边导航菜单 -->
  <el-menu
    :default-active="activeIndex"
    class="menu"
    unique-opened="true"
    router="true"
  >
    <el-menu-item index="/backstage"> 概况 </el-menu-item>
    <el-sub-menu index="1">
      <template #title>
        <span>数据记录</span>
      </template>
      <el-menu-item index="/environment"> 环境数据 </el-menu-item>
      <el-menu-item index="/warning"> 报警记录 </el-menu-item>
      <el-menu-item index="/plan"> 种植计划 </el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="2">
      <template #title>
        <span>设施总览</span>
      </template>
      <el-menu-item index="/sensor"> 传感器总览 </el-menu-item>
      <el-menu-item index="/equipment"> 接入设备总览 </el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="3">
      <template #title>
        <span>控制管理</span>
      </template>
      <el-menu-item index="/control"> 环控规则 </el-menu-item>
      <el-menu-item index="/warning-manage"> 报警规则 </el-menu-item>
    </el-sub-menu>
    <el-menu-item index="/field"> 箱体信息 </el-menu-item>
    <el-menu-item index="/user"> 个人中心 </el-menu-item>
  </el-menu>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
//绑定当前路由
const activeIndex = ref("");

//监听路由跳转
watch(
  () => router.currentRoute.value.path,
  (toPath) => {
    activeIndex.value = toPath;
  },
  { immediate: true, deep: true } //不加的话进入首页不跟踪
);
</script>

<style scoped lang="less">
.menu {
  position: absolute;
  width: 200px;
  height: 100%;
}
</style>