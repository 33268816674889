<template>
  <card-item class="card" title="报警情况" cardWidth="100%" cardHeight="100%">
    <template v-slot:content>
      <!-- 情况总览 -->
      <el-row
        class="outline"
        type="flex"
        align="middle"
        style="padding-left: 20rem"
      >
        <el-col :span="4">
          <img class="ico" v-bind:src="warningUrl" alt="warning" />
        </el-col>
        <el-col :span="8">
          <div>
            现有报警 <span class="emphasize">{{ total }}</span
            >次
          </div>
        </el-col>
      </el-row>
      <!-- 数据表格 -->
      <el-table
        :data="tableData"
        class="table"
        size="mini"
        :row-class-name="tableRowClassName"
        style="width: 90%; height: 60%; font-size: 16rem; margin-left: 5%"
      >
        <el-table-column prop="datetime" label="报警时间" align="center" />
        <el-table-column prop="category" label="报警类别" align="center" />
        <el-table-column prop="content" label="报警内容" align="center" />
      </el-table>
    </template>
  </card-item>
</template>
    
<script setup>
import CardItem from "@/components/cards/card.vue";
import { useBarnStore } from "@/stores/barn";
import { queryWarningDataAPI } from "@/apis/monitorAPI";
import { onMounted, ref, watch } from "vue";

//调取图片路径函数
const getImgUrl = (add) => {
  return require("@/assets/" + add);
};
const warningUrl = getImgUrl("warning.png");

const tableRowClassName = ({ row, rowIndex }) => {
  if ((rowIndex + 1) % 2 == 0) {
    return "second-row";
  } else {
    return "first-row";
  }
};

//参数数据
const barnStore = useBarnStore();

const total = ref(0);
//表格数据
const tableData = ref([]);
const queryWarningData = async () => {
  total.value = 0;
  const { data } = await queryWarningDataAPI(barnStore.barnId);
  tableData.value = data;
  total.value = data.length;
};

//初始化
queryWarningData();

onMounted(() => {
  watch(
    () => barnStore.barnId,
    () => {
      queryWarningData();
    }
  );
});
</script>
    
<style scoped lang="less">
.card {
  .outline {
    height: 25%;
    width: 100%;
    .content {
      text-align: center;
      color: @green;
      font-size: 20rem;
    }
    .emphasize {
      padding-left: 10rem;
      font-size: 35rem;
      margin-top: 15rem;
      padding-top: 15rem;
      color: @green;
    }
  }
  .image {
    display: inline-block;
    width: 100%;
  }
  ::v-deep .el-table {
    .first-row {
      background: @darkerGreen;
    }
    .second-row {
      background: @transparent;
    }
    --el-table-tr-bg-color: @transparent;
    --el-table-bg-color: @transparent;
    --el-table-header-bg-color: @transparent;
    --el-table-border-color: @transparent;
    --el-table-header-text-color: @green;
    --el-table-text-color: white;
    --el-table-row-hover-bg-color: @transparent;
  }
  ::v-deep .el-table__header-wrapper {
    font-size: 20rem;
  }
}
</style>