<template>
  <card-item class="card" title="实时数据" cardWidth="100%" cardHeight="100%">
    <template v-slot:content>
      <!-- 数据显示 -->
      <!-- 温度 -->
      <el-row
        style="margin-top: 5%; padding-left: 5%; height: 25%; width: 100%"
        type="flex"
        align="middle"
      >
        <el-col :span="6" class="data-table">
          <img class="ico" v-bind:src="temperatureUrl" alt="temperature" />
        </el-col>
        <el-col :span="6" class="data-table">
          <div>
            <span class="emphasize">{{ temperature }}</span
            ><span class="unit">℃</span>
          </div>
          <div>温度</div>
        </el-col>
        <el-col :span="2" class="data-table">
          <div>
            <img class="index" v-bind:src="indexUrl" alt="index" />
          </div>
        </el-col>
        <el-col :span="10" class="data-table">
          <div>温度阈值<br />{{ temperatureT }}℃</div>
        </el-col>
      </el-row>
      <!-- 湿度 -->
      <el-row
        class="middle-part"
        style="padding-left: 5%; height: 25%; width: 100%"
        type="flex"
        align="middle"
      >
        <el-col :span="6" class="data-table">
          <img class="ico" v-bind:src="humidityUrl" alt="humidity" />
        </el-col>
        <el-col :span="6" class="data-table">
          <div>
            <span class="emphasize">{{ humidity }}</span
            ><span class="unit">%</span>
          </div>
          <div>湿度</div>
        </el-col>
        <el-col :span="2" class="data-table">
          <div>
            <img class="index" v-bind:src="indexUrl" alt="index" />
          </div>
        </el-col>
        <el-col :span="10" class="data-table">
          <div>湿度阈值<br />{{ humidityT }}%</div>
        </el-col>
      </el-row>
      <!-- 二氧化碳 -->
      <el-row
        style="padding-left: 5%; height: 25%; width: 100%"
        type="flex"
        align="middle"
      >
        <el-col :span="6" class="data-table">
          <img class="ico" v-bind:src="carbonUrl" alt="carbon" />
        </el-col>
        <el-col :span="6" class="data-table">
          <div>
            <span class="emphasize">{{ carbon }}</span
            ><span class="unit">PPM</span>
          </div>
          <div>二氧化碳</div>
        </el-col>
        <el-col :span="2" class="data-table">
          <div>
            <img class="index" v-bind:src="indexUrl" alt="index" />
          </div>
        </el-col>
        <el-col :span="10" class="data-table">
          <div>浓度阈值<br />{{ carbonT }}PPM</div>
        </el-col>
      </el-row>
    </template>
  </card-item>
</template>

<script setup>
import CardItem from "@/components/cards/card.vue";
import { useBarnStore } from "@/stores/barn";
import {
  queryEnvironmentNowAPI,
  queryEnvironmentThresholdAPI,
} from "@/apis/monitorAPI";
import { onMounted, ref, watch } from "vue";

//调取图片路径函数
const getImgUrl = (add) => {
  return require("@/assets/" + add);
};
const temperatureUrl = getImgUrl("temperature.png");
const humidityUrl = getImgUrl("humidity.png");
const carbonUrl = getImgUrl("carbon.png");
const indexUrl = getImgUrl("index.png");

//获取场区信息
const barnStore = useBarnStore();

//参数相关
const temperature = ref(0.0);
const humidity = ref(0.0);
const carbon = ref(0);
const temperatureT = ref(0.0);
const humidityT = ref(0.0);
const carbonT = ref(0);

const queryEnvironmentData = async () => {
  temperature.value = 0;
  humidity.value = 0;
  carbon.value = 0;
  const { data } = await queryEnvironmentNowAPI(barnStore.barnId);
  for (let item of data) {
    if (item.name == "温度") {
      temperature.value = item.data;
    } else if (item.name == "湿度") {
      humidity.value = item.data;
    } else if (item.name == "二氧化碳") {
      carbon.value = item.data;
    }
  }
};
const queryEnvironmentT = async () => {
  temperatureT.value = 0;
  humidityT.value = 0;
  carbonT.value = 0;
  const { data } = await queryEnvironmentThresholdAPI(barnStore.barnId);
  temperatureT.value = data.temHigh;
  humidityT.value = data.rhHigh;
  carbonT.value = data.gasHigh;
};

queryEnvironmentData();
queryEnvironmentT();

onMounted(() => {
  watch(
    () => barnStore.barnId,
    () => {
      queryEnvironmentData();
    }
  );
});
</script>

<style scoped lang="less">
.card {
  .data-table {
    font-family: "Microsoft YaHei";
    .unit {
      color: @green;
      font-size: 15rem;
    }
    .emphasize {
      color: @green;
      font-size: 30rem;
    }
  }
  .ico {
    height: 100rem;
  }
  .middle-part {
    border: 3rem solid;
    border-image: linear-gradient(
      90deg,
      @transparent 20%,
      @green 30%,
      @green 70%,
      @transparent 80%
    );
    border-image-slice: 1;
  }
}
</style>