<template>
  <div class="card">
    <el-row class="title-row">
      <el-col :span="3">
        <img class="ico" v-bind:src="icoUrl" alt="title-ico" />
      </el-col>
      <el-col :span="12">
        <div class="card-title">
          {{ title }}
        </div>
      </el-col>
      <el-col :span="8">
        <slot name="title">
      </slot>
      </el-col>
    </el-row>
    <div class="frame">
      <slot name="content">
        <div>默认内容</div>
      </slot>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    default: "标题",
    type: String,
  },
  cardWidth: {
    default: "300rem",
    type: String,
  },
  cardHeight: {
    default: "30%",
    type: String,
  },
});

//调取图片路径函数
const getImgUrl = (add) => {
  return require("@/assets/" + add)
}
const icoUrl = getImgUrl("title-ico.png");

</script>

<style scoped lang="less">
.card {
  display: flex;
  position: absolute;
  width: v-bind(cardWidth);
  height: v-bind(cardHeight);
  .title-row{
    background-image: url(@/assets/card-title.png);
    background-size: 100%;
    background-repeat: no-repeat;
    height: 60rem;
    width: 100%;
    margin: 0;
    font-weight: bold;
    .ico{
      height: 60rem;
      width: 60rem;
    }
    .card-title {
      font-size: 30rem;
      font-family: @title;
      color: @light;
      line-height: 60rem;
      height: 100%;
      width: 100%;
    }
  }
  .frame {
    position: absolute;
    background-image: url(@/assets/card-background.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    top: 80rem;
    bottom: 0;
    font-size: 20rem;
    color: white;
  }
}
</style>