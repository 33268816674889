import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'
import Login from '@/views/login/index.vue'
import Monitor from '@/views/monitor/index.vue'
import Layout from '@/views/layout/index.vue'
import Overview from '@/views/overview/index.vue'
import Environment from '@/views/data/environment/index.vue'
import Warning from '@/views/data/warning/index.vue'
import Plan from '@/views/data/plan/index.vue'
import Sensor from '@/views/list/sensor/index.vue'
import Equipment from '@/views/list/equipment/index.vue'
import Control from '@/views/manage/control/index.vue'
import WarningManage from '@/views/manage/warning/index.vue'
import Field from '@/views/information/field/index.vue'
import User from '@/views/information/user/index.vue'
import Password from '@/views/information/user/password.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/main-screen',
    name: 'main-screen',
    component: Monitor
  },
  {
    path: '/backstage',
    name: 'backstage',
    component: Layout,
    children: [
      {
        path: '',
        name: 'overview',
        component: Overview
      },
      {
        path: '/environment',
        name: 'environment-data',
        component: Environment
      },
      {
        path: '/warning',
        name: 'warning-data',
        component: Warning
      },
      {
        path: '/plan',
        name: 'plan',
        component: Plan
      },
      {
        path: '/sensor',
        name: 'sensor-list',
        component: Sensor
      },
      {
        path: '/equipment',
        name: 'equipment-list',
        component: Equipment
      },
      {
        path: '/control',
        name: 'environment-control',
        component: Control
      },
      {
        path: '/warning-manage',
        name: 'warning-manage',
        component: WarningManage
      },
      {
        path: '/field',
        name: 'field-manage',
        component: Field
      },
      {
        path: '/user',
        name: 'user-manage',
        component: User
      },
      {
        path: '/user/password',
        name: 'password',
        component: Password
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) =>{
  const store = useUserStore();
  if(to.path ==='/login'){
    next()
  } else{
    if(store.token){
      next()
    } else {
      next({name: 'login'})
    }
  }
})

export default router
