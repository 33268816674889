<template>
    <div class="card">
      <div class="title">种植计划</div>
      <el-table class="table" :data="dataList" border="true">
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="60"
          :index="indexMethod"
        />
        <el-table-column prop="active" label="状态" width="60">
          <template #default="scope">
            <el-switch
              v-model="scope.row.active"
              disabled
              size="small"
              active-value="1"
              inactive-value="0"
            />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="计划名称" width="100">
          <template #header>
            <div class="header-cell">
              计划<br/>名称
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="days" label="持续天数">
          <template #header>
            <div class="header-cell">
              天数<br/>/天
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="temperature" label="温度阈值">
          <template #header>
            <div class="header-cell">
              温度阈值<br/>/℃
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="humidity" label="湿度阈值">
        <template #header>
            <div class="header-cell">
              湿度阈值<br/>/%
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="carbon" label="CO2阈值">
        <template #header>
            <div class="header-cell">
              CO2阈值<br/>/ppm
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="lightOn" label="灯光开">
        <template #header>
            <div class="header-cell">
              灯光开启<br/>/min
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="lightOff" label="灯光关">
        <template #header>
            <div class="header-cell">
              灯光关闭<br/>/min
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="sprayOn" label="喷淋开">
          <template #header>
            <div class="header-cell">
              喷淋开启<br/>/min
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="sprayOff" label="喷淋关">
        <template #header>
            <div class="header-cell">
              喷淋关闭<br/>/min
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </template>
  
  <script setup>
  import { useBarnStore } from "@/stores/barn";
  import { queryPlanAPI } from "@/apis/dataAPI";
  import { onMounted, ref, watch } from "vue";
  
  //获取畜舍id
  const barnStore = useBarnStore();
  
  //接收数据
  const dataList = ref([]);
  let barnId = 1;
  
  //参数相关
  const queryPlanData = async () => {
    const { data } = await queryPlanAPI(barnId);
    dataList.value = data;
  };
  
  //数据初始化
  queryPlanData();
  
  onMounted(() => {
    watch(
      () => barnStore.barnId,
      () => {
        selectClear();
        queryPlanData();
      }
    );
  });
  </script>
  
  <style scoped lang="less">
  .card {
    width: 100%;
    min-height: 550px;
    background-color: white;
    .title {
      font-family: @title;
      font-weight: bold;
      font-size: 22px;
      height: 30px;
      line-height: 30px;
      padding-top: 20px;
      padding-left: 35px;
    }
    .tool-row {
      width: 850px;
      height: 50px;
      left: 35px;
      margin-top: 15px;
    }
    .table {
      margin-top:10px;
      width: 850px;
      height: 440px;
      left: 50%;
      transform: translateX(-50%);
      font-family: @text;
      font-size: 12px;
      color: black;
      .header-cell{
        text-align: center;
      }
    }
    .pagination {
      position: relative;
      float: right;
    }
  }
  </style>