<template>
  <div class="card">
    <div class="title">传感器总览</div>
    <el-row class="tool-row" align="middle">
      <el-col :span="3"> 仪器类别 </el-col>
      <el-col :span="6">
        <el-select
          v-model="categorySelect"
          multiple
          clearable
          collapse-tags
          collapse-tags-tooltip
          placeholder="全部"
          style="width: 100%"
        >
          <el-option
            v-for="(item, index) in categoryList"
            :key="index"
            :value="item"
          /> </el-select
      ></el-col>
      <el-col :span="2" :offset="1">
        <el-button @click="select" type="primary">选择</el-button>
      </el-col>
    </el-row>
    <el-table class="table" :data="dataList" border="true">
      <el-table-column
        type="index"
        label="序号"
        align="center"
        width="60"
        :index="indexMethod"
      />
      <el-table-column prop="sensorId" label="仪器编号" width="150" />
      <el-table-column prop="category" label="仪器类别" width="150" />
      <el-table-column prop="name" label="仪器名称" width="150" />
      <el-table-column prop="note" label="备注" />
      <el-table-column fixed="right" label="操作" width="80">
        <template #default="scope">
          <el-button
            link
            type="primary"
            size="small"
            @click.prevent="modifyRow(scope.$index)"
          >
            信息修改
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="tool-row" align="middle" style="height: 50px; margin-top: 0">
      <el-col :span="2">
        <el-button @click="output" type="primary">导出</el-button>
      </el-col>
      <el-col :span="14" :offset="8">
        <el-pagination
          class="pagination"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="totalNumber"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
  </div>
  <data-dialog
    :modifyRule="modifyRule"
    :rowData="rowData"
    @close-dialog="closeDialog"
    @submit-data="updateData"
    :before-close="closeDialog"
  ></data-dialog>
</template>
  
<script setup>
import { useBarnStore } from "@/stores/barn";
import {
  updateSensorAPI,
  querySensorCategoryListAPI,
  queryEnvironmentSensorAPI,
} from "@/apis/sensorAPI";
import DataDialog from "./dialog/dialog.vue";
import * as Category from "@/constant/data-category";
import Download from "@/utils/export";
import { onMounted, ref, toRaw, watch } from "vue";

//获取畜舍id
const barnStore = useBarnStore();

//表格页码相关
const totalNumber = ref(10);
const pageSize = ref(10);
const currentPage = ref(1);
const handleCurrentChange = (val) => {
  currentPage.value = val;
  queryEnvironmentSensor();
};
//重构表格序号
const indexMethod = (index) =>
  index + (currentPage.value - 1) * pageSize.value + 1;

//参数相关
const categorySelect = ref([]);
const selectClear = () => {
  categorySelect.value = [];
};

//接收数据
const categoryList = ref([]);
const dataList = ref([]);
const rawData = ref([]);
let barnId = "";
let barnName = "";
let name = "";

//参数相关
const queryCategoryList = async () => {
  const { data } = await querySensorCategoryListAPI({
    barnId: barnStore.barnId,
    sensorClass: Category.ENVIRONMENT_SENSOR,
  });
  categoryList.value = data;
};
const queryEnvironmentSensor = async () => {
  barnId = barnStore.barnId;
  barnName = barnStore.barnName;
  name = categorySelect.value.toString();
  const { data } = await queryEnvironmentSensorAPI({
    barnId: barnId,
    pageNo: currentPage.value,
    pageSize: pageSize.value,
    category: name,
  });
  totalNumber.value = data.total;
  dataList.value = data.list;
};
const updateSensor = async (data) => {
  await updateSensorAPI({
    sensorId: data.sensorId,
    name: data.name,
    note: data.note,
  });
};
const queryRawData = async () => {
  const { data } = await queryEnvironmentSensorAPI({
    barnId: barnId,
    pageSize: 0,
    category: name,
  });
  rawData.value = data.list;
};

//数据初始化
queryCategoryList();
queryEnvironmentSensor();

//选择按钮函数
const select = () => {
  currentPage.value = 1;
  queryEnvironmentSensor();

};
const output = async () => {
  await queryRawData()
  Download(rawData.value, barnName + "—环境传感器列表")
};

//修改对话框相关
const modifyRule = ref(false);
const rowData = ref({
  sensorId: String,
  category: String,
  name: String,
  note: String,
});

//修改按钮函数
const modifyRow = (i) => {
  rowData.value = dataList.value[i];
  modifyRule.value = true;
};
//对话框提交数据函数
const updateData = async (data) => {
  await updateSensor(toRaw(data))
  queryEnvironmentSensor()
  modifyRule.value = false;
};
//关闭对话框函数
const closeDialog = () => {
  modifyRule.value = false;
};

//页面刷新函数
onMounted(() => {
  watch(
    () => barnStore.barnId,
    () => {
      selectClear();
      queryEnvironmentSensor();
      queryCategoryList();
    }
  );
});
</script>
  
<style scoped lang="less">
.card {
  width: 100%;
  min-height: 550px;
  background-color: white;
  .title {
    font-family: @title;
    font-weight: bold;
    font-size: 22px;
    height: 30px;
    line-height: 30px;
    padding-top: 20px;
    padding-left: 35px;
  }
  .tool-row {
    width: 850px;
    height: 50px;
    left: 35px;
    margin-top: 15px;
    font-size: 16px;
  }
  .table {
    width: 850px;
    height: 440px;
    left: 50%;
    transform: translateX(-50%);
    font-family: @text;
    color: black;
  }
  .pagination {
    position: relative;
    float: right;
  }
}
</style>