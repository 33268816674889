<template>
  <div class="card">
    <el-row class="tool-row" align="middle">
      <div class="url" align="right">
        <router-link :to="{ path: '/user' }">
          &lt;&lt;返回个人中心
        </router-link>
      </div>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 50px;">
      <el-col :span="12">
        <el-form
          ref="formRef"
          style="max-width: 600px"
          :model="ruleForm"
          :rules="rules"
          label-width="auto"
          :size="formSize"
          status-icon
        >
          <el-form-item label="输入新的密码" prop="password">
            <el-input
              v-model="ruleForm.password"
              style="width: 240px"
              type="password"
              placeholder="请输入6-12位密码"
              minlength="6"
              maxlength="12"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="确认新的密码" prop="passwordAgain">
            <el-input
              v-model="ruleForm.passwordAgain"
              style="width: 240px"
              type="password"
              placeholder="再次输入密码"
              minlength="6"
              maxlength="12"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit">
              确认
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
    
<script setup>
import { useUserStore } from "@/stores/user";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { changePasswordAPI } from "@/apis/userAPI";
import { ref, toRaw  } from "vue";

//获取用户id
const userStore = useUserStore();
let userId = userStore.userId;

//制表数据
const ruleForm = ref({
  password: "",
  passwordAgain: "",
});
const formRef = ref(null);
const rules = {
  password: [
    { required: true, message: "请输入密码", trigger: "blur" },
    { min: 6, max: 12, message: "长度在6~12位", trigger: "blur" },
  ],
  passwordAgain: [
    { required: true, message: "请输入密码", trigger: "blur" },
    {
      validator: (rule, value, callback) => {
        if (value !== ruleForm.value.password) {
          callback(new Error("两次输入不一致"));
        } else {
          callback();
        }
      },
      trigger: "blur",
    },
  ],
};

const router = useRouter();
//提交按钮
const submit = async () => {
  /* 重改密码提交函数 */
  formRef.value.validate(async (valid) => {
    if (!valid) {
      ElMessage.error("密码修改失败")
      return false;
    }
    ElMessage.success("密码修改成功")

    const param = {
      id: userId,
      password:ruleForm.value.password
    }

    await changePasswordAPI(param);
    router.push({ path: "/user" });
  });
};

</script>
    
<style scoped lang="less">
.card {
  width: 100%;
  min-height: 550px;
  background-color: white;
  .url {
    font-size: 15px;
    color: #7b7d7e;
    margin-top: 10px;
    margin-left: 10px;
  }
  .label {
    text-align: right;
  }
}
</style>