<template>
  <el-config-provider :locale="locale">
  <div>
    <router-view />
  </div>
</el-config-provider>
</template>

<script setup>
import { ElConfigProvider } from 'element-plus';
import zhCn from "element-plus/es/locale/lang/zh-cn";
import { useDynamicRem } from './hooks/useDynamicRem';

useDynamicRem();

const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);

    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}

const locale = zhCn;
</script>

<style lang="less">
a:link{
  text-decoration:none;
  color:inherit;
}

a:visited{
  text-decoration:none;
  color:inherit;
}

a:hover{
  color: @grey;
}
</style>
