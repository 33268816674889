import instance from "@/utils/request"
const baseURL = '/monitor/';

/**
 * 获取当前环境数据
 * @param {Number} barnId 舱体id
 * @returns 响应结果
 */
export const queryEnvironmentNowAPI = (barnId) =>{
    return instance({
        url: baseURL + 'environment/data/' + barnId,
    })
}

/**
 * 获取当前环境阈值
 * @param {Number} barnId 舱体id
 * @returns 响应结果
 */
export const queryEnvironmentThresholdAPI = (barnId) =>{
    return instance({
        url: baseURL + 'environment/controlRule/' + barnId,
    })
}

/**
 * 获取当前场区箱体列表
 * @param {Number} fieldId 场区id
 * @returns 响应结果
 */
export const queryBarnDataAPI = (fieldId) =>{
    return instance({
        url: baseURL + 'barn/' + fieldId,
    })
}

/**
 * 获取当前舍传感器数量
 * @param {Number} barnId 舱体id
 * @returns 响应结果
 */
export const querySensorCountAPI = (barnId) =>{
    return instance({
        url: baseURL + 'environment/count/' + barnId,
    })
}

/**
 * 获取当前舍设备情况
 * @param {Number} barnId 舱体id
 * @returns 响应结果
 */
export const queryEquipmentCountAPI = (barnId) =>{
    return instance({
        url: baseURL + 'equipment/count/' + barnId,
    })
}

/**
 * 获取当前舍设备开启情况
 * @param {Number} barnId 舱体id
 * @returns 响应结果
 */
export const queryEquipmentDataAPI = (barnId) =>{
    return instance({
        url: baseURL + 'equipment/data/' + barnId,
    })
}

/**
 * 获取当前舍报警记录
 * @param {Number} barnId 舱体id
 * @returns 响应结果
 */
export const queryWarningDataAPI = (barnId) =>{
    return instance({
        url: baseURL + 'warning/data/' + barnId,
    })
}

/**
 * 获取控制器状态
 * @param {Number} barnId 舱体id
 * @returns 响应结果
 */
export const queryControllerDataAPI = (barnId) =>{
    return instance({
        url: baseURL + 'controller/data/' + barnId,
    })
}

/**
 * 获取舱体信息
 * @param {Number} barnId 舱体id
 * @returns 响应结果
 */
export const queryBarnMessageAPI = (barnId) =>{
    return instance({
        url: baseURL + 'message/' + barnId,
    })
}

