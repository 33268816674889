<template>
  <el-row class="row-data" type="flex" align="middle">
    <el-col :span="8">
      <div>控制器状态</div><br/>
      <div><span class="emphasize">{{ status }}</span></div>
    </el-col>
    <el-col :span="8">
      <div>总计划天数</div><br/>
      <div><span class="emphasize">{{ total }}</span>天</div>
    </el-col>
    <el-col :span="8">
      <div>当前天数</div><br/>
      <div><span class="emphasize">{{ today }}</span>天</div>
    </el-col>
  </el-row>
</template>
  
<script setup>
import { useBarnStore } from "@/stores/barn";
import { queryControllerDataAPI, queryBarnMessageAPI } from "@/apis/monitorAPI";
import { onMounted, ref, watch } from "vue";

//获取场区信息
const barnStore = useBarnStore();

//参数相关
const status = ref("离线")
const total = ref(0)
const today = ref(0)

//获取数据函数
const queryControllerData = async () => {
  const { data } = await queryControllerDataAPI(barnStore.barnId);
  status.value = data.operation ? (data.operation.includes("开") ? "在线" : "离线") : "离线"
};

const queryBarnMessage = async () => {
  const { data } = await queryBarnMessageAPI(barnStore.barnId);
  today.value = data.today
  total.value = data.totalDays
};

// 初始化
queryControllerData()
queryBarnMessage()

onMounted(() => {
  watch(
    () => barnStore.barnId,
    () => {
      queryControllerData();
      queryBarnMessage()
    }
  );
});


</script>
  
<style scoped lang="less">
.row-data {
  font-size: 30rem;
  color: white;
  text-align: center;
  font-weight: bold;
  .emphasize {
    color: @green;
    font-weight: bold;
    font-size: 50rem;
  }
}
</style>